import { Injectable } from '@angular/core';
import { AngularFireAuth } from '@angular/fire/auth';
import { AngularFirestore, AngularFirestoreCollection } from '@angular/fire/firestore';

import { Observable, of, Subject, combineLatest, ReplaySubject } from 'rxjs';
import { map, shareReplay, first, takeUntil, tap, filter, debounceTime, mergeMap } from 'rxjs/operators';

import { User, Role, UserInternal, UserInfo } from './user';
import { ConfigService } from 'src/app/admin/shared/config.service';

import * as _ from 'underscore';
import { NotificationService } from 'src/app/shared/notification.service';
import { AngularFireFunctions } from '@angular/fire/functions';
import { SurveyResult } from 'src/app/articles/shared/surveyResult';

@Injectable()
export class UserService {
  private name = 'users';
  private nameInternal = 'usersInternal';

  collection: AngularFirestoreCollection<User> = null;
  userInfo: UserInfo;
  userInfoObs: Observable<UserInfo>;
  userInfos: Observable<UserInfo[]>;
  usersCached = new Map<string,Observable<User>>();
  initialized = new ReplaySubject<Boolean>(1);
  registrationCodeHash: string; // caching the registration code for signup
  registrationCodeHashOk: Boolean = false;
  myCategories: string[];
  myCategoriesObs = new ReplaySubject<string[]>(1);
  userUnsubscribe = new Subject<void>(); // internal: needed to trigger unsubscribe from user observable

  constructor( private afAuth: AngularFireAuth, private db: AngularFirestore, private functions: AngularFireFunctions
             , private config: ConfigService, private notificationSvc: NotificationService ) {

    // init
    this.collection = db.collection<User>(this.name);
    this.myCategoriesObs.subscribe( c => console.log('my categories', c));
    this.initialized.subscribe( x => console.log("user service initialized"));

    // init user and get updates
    this.userInfoObs = this.afAuth.authState.pipe(
      mergeMap( fbUser => {
        if ( fbUser && afAuth.currentUser ) {
          const userId =  fbUser.uid;
          return combineLatest([this.getCached(userId), this.getInternal(userId), this.getRoles(userId)])
          .pipe(
            map( ([user, internal, roles]) => new UserInfo(user, internal, roles)),
            shareReplay(1),
            takeUntil(this.userUnsubscribe)
          )
        } else {
          this.myCategories = [];
          this.myCategoriesObs.next([]);
          this.userInfo = null;
          this.userInfos = null;
          this.registrationCodeHash = null;
          this.registrationCodeHashOk = false;
          this.initialized.next(true);
          console.log("no user");
          return of(null);
        }
      }),
      shareReplay(1) // cache latest
    );

    // wait for config observer
    combineLatest([this.userInfoObs.pipe(filter(user => user != null)), this.config.configObs])
    .subscribe( ([userInfo,config]) => {
      this.userInfo = userInfo;
      console.log('got user update', userInfo);
      // prepare user internal
      this.registrationCodeHashOk = this.config.isRegistrationCodeHashOk(userInfo.internal.registrationCodeHash);
      console.log('codeCheck', this.registrationCodeHashOk)
      // prepare my categories
      const registredCategoryNames = userInfo.getCategories();
      this.myCategories = this.config.expandCategoriesWithParents(registredCategoryNames).sort();
      this.myCategoriesObs.next(this.myCategories);
      // finalize
      this.initialized.next(true);
    });
    console.log('user service constructor finished');
  }

  emailSignUp(email: string, password: string, userData: Partial<User> = {}, login: boolean = true) {
    // create firebase user
    var userPromise = (login ? this.afAuth.createUserWithEmailAndPassword(email, password) : this.createFbUser(email, password));
    return userPromise
    .then(userCred => {
      userData.id = userCred.user.uid
      userData.email = userCred.user.email
      console.log("created firebase user", userCred.user);
      // create internal user config
      return this.createOrUpdateUserInternal( {id: userData.id, registrationCodeHash: this.registrationCodeHash || this.config.config.registrationCodeHash})
      // create general user document
      .then(() => this.create(userData))
      .then(() => userData)
    })
  }

  // this creates the user without logging in, not as afAuth.createUserWithEmailAndPassword
  createFbUser(email: string, password: string) {
    return this.functions.httpsCallable('createUser')({email: email, password: password}).toPromise()
    .then(user => ({user: user}));
  }  

  generatePassword(length: number): string {
    var chars = "0123456789ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz";
    var pw = Array(length);
    for(var i=0; i<length; i++) {
      pw[i] = chars[Math.floor(Math.random() * chars.length)];
    }
    return pw.join("");
  }

  emailLogin(email: string, password: string) {
    this.initialized.next(false); 
    return this.afAuth.signInWithEmailAndPassword(email, password);
  }

  emailLinkLogin(email: string, url: string) {
    this.initialized.next(false); 
    return this.afAuth.signInWithEmailLink(email, url);
  }

  logout() {
    this.userUnsubscribe.next(); // trigger unsubscribe from user observable to avoid Missing or insufficient permissions error
    this.initialized.next(false); 
    return this.afAuth.signOut();
  }

  // cached list for all user information
  getUserInfoList(): Observable<UserInfo[]> {
    if (!(this.userInfo.isAdmin() || this.userInfo.isVorstand() || this.userInfo.isEditor() || this.userInfo.isConfirmedEv())) return of([]);
    if (!this.userInfos) {
      const myConfirmedEvCategories = this.userInfo.getConfirmedEvCategories();
      const users = this.collection
      .snapshotChanges()
      .pipe(
        debounceTime(1000), // avoid using the cached value (https://github.com/angular/angularfire2/issues/2012)
        map( changes => changes.map( obj => ({ id: obj.payload.doc.id, ...obj.payload.doc.data() }))),
        map( objs => objs.filter( obj => this.userInfo && (this.userInfo.isAdmin() || this.userInfo.isVorstand() || this.userInfo.isEditor() ||
          (this.userInfo.isConfirmedEv() && obj.categories && _.intersection(obj.categories.map( c => c.category ), myConfirmedEvCategories ).length>0 )))),
        map( objs => _.sortBy(objs, obj => obj.lastName)),
        shareReplay(1), // cache all
        takeUntil(this.userUnsubscribe)
      );
      const usersInternal = this.db.collection<UserInternal>(this.nameInternal)
      .snapshotChanges()
      .pipe(
        map( changes => changes.map( obj => ({ id: obj.payload.doc.id, ...obj.payload.doc.data() }))),
        shareReplay(1), // cache all
        takeUntil(this.userUnsubscribe)
      );
      const allRoles = this.db.collectionGroup<Role>("roles")
      .snapshotChanges()
      .pipe(
        map( changes => changes.map( obj => ({ id: obj.payload.doc.id, ...obj.payload.doc.data() }))),
        shareReplay(1), // cache all
        takeUntil(this.userUnsubscribe)
      );
      this.userInfos = combineLatest(users, usersInternal, allRoles)
      .pipe(
        map(([users,usersInternal,allRoles]) => users.map( user => {
            const internal = usersInternal.find( internal => internal.id == user.id);
            const roles = allRoles.filter( role => role.userId == user.id).map( role => role.id );
            return new UserInfo(user, internal, roles);;
        })),
        // check registration hash code from internal user
        map(userInfos => userInfos.filter( user => this.userInfo.isAdmin() || this.config.isRegistrationCodeHashOk(this.userInfo.internal.registrationCodeHash))),
        shareReplay(1) // cache all
      );
    }
    return this.userInfos;
  }

  getCached(id: string): Observable<User> {
    if (this.userInfos) {
      return this.userInfos.pipe( map(users => users.find( user => user.user.id == id).user));
    } else {
      const cachedUser = this.usersCached.get(id)
      if (cachedUser) {
        return cachedUser
      } else {
        return this.get(id);
      }
    }
  }

  getCachedInternal(id: string): Observable<UserInternal> {
    return this.getUserInfoList().pipe( 
      map(users => users.find( user => user.internal.id == id).internal)
    );
  }  

  // this is uncached!
  get(id: string): Observable<User> {
    const user = this.collection.doc<User>(id).snapshotChanges()
    .pipe( 
      map( obj => ({ id: obj.payload.id, ...obj.payload.data()})),
      shareReplay(1)
    );
    this.usersCached.set(id, user);
    return user;
  }


  // this is uncached!
  getInternal(id: string): Observable<UserInternal> {
    const userInternal = this.db.collection(this.nameInternal).doc<UserInternal>(id).snapshotChanges()
    .pipe( 
      map( obj => ({ id: obj.payload.id, ...obj.payload.data()})),
      shareReplay(1)
    );
    return userInternal;
  }

  // this is uncached!
  getMySurveyResult(articleId: string): Observable<SurveyResult> {
    if (!this.isLoggedIn()) return of(null);
    const mySurveyCollection = this.collection.doc(this.userInfo.user.id).collection<SurveyResult>('surveyResult');
    const surveyResult = mySurveyCollection.doc<SurveyResult>(articleId).snapshotChanges()
    .pipe( 
      map( obj => ({ id: obj.payload.id, ...obj.payload.data()}))
    );
    return surveyResult;
  }  


  // this is uncached!
  getSurveyResults(articleId: string): Observable<SurveyResult[]> {
    const surveyResults = this.db.collectionGroup<SurveyResult>('surveyResult', ref => ref.where('id', '==', articleId))
    .snapshotChanges()
    .pipe( 
      map( changes => changes.map( obj => ({ id: obj.payload.doc.id, ...obj.payload.doc.data() }))),
      shareReplay(1)
    );
    return surveyResults;
  }    

  addMySurveyResult(articleId: string, result: SurveyResult ): Promise<void> {
    const mySurveyCollection = this.collection.doc(this.userInfo.user.id).collection<SurveyResult>('surveyResult');
    return mySurveyCollection.doc(articleId).set(result);
  }
    
  getCachedRoles(userId: string): Observable<string[]> {
    return this.getUserInfoList().pipe(
      map( users => users.find( user => user.user.id == userId).roles)
    )
  }

  // this is uncached!
  getRoles(userId: string): Observable<string[]> {
    return this.collection.doc<Role>(userId).collection('roles').snapshotChanges()
      .pipe(
        map( objs => objs.map( obj => obj.payload.doc.id)),
        shareReplay(1)
      );
  }

  create( obj: Partial<User> ): Promise<void> {
    console.log('creating user', obj);
    return this.collection.doc<Partial<User>>(obj.id).set(obj);
  }

  update( obj: Partial<User> ) {
    console.log('updating user', obj);
    return this.collection.doc<User>(obj.id).update(obj);
  }

  deleteCurrentUser() {
    console.log('deleting current user', this.userInfo.user.id);
    // delete internal user
    // TODO: delete userInternal and subcollection roles
    const userDoc = this.collection.doc(this.userInfo.user.id)
    return userDoc.delete()
    // then delete firebase user
    .then( x => this.afAuth.currentUser.then(user => user.delete()));
  }

  deleteUser(userId: string) {
    console.log('deleting user', userId);
    // delete internal user
    // TODO: delete userInternal and subcollection roles
    return this.collection.doc(userId).delete();
  }  

  sendEmailVerification() {
    console.log('sending email verification');
    this.afAuth.languageCode = Promise.resolve('de');
    return this.afAuth.currentUser.then(user => user.sendEmailVerification());
  }

  sendPasswordResetEmail( email: string ) {
    console.log('sending password reset email to ' + email);
    this.afAuth.languageCode = Promise.resolve('de');
    return this.afAuth.sendPasswordResetEmail( email );
  }

  getFbUserData() {
    return this.functions.httpsCallable('getFbUserData')({message: 'all'}).pipe(
      tap(result => console.log("got fb user data", result))
    )
  }

  isInitialized() {
    return this.initialized;
  }

  isLoggedIn() {
    return this.userInfo ? true : false;
  }

  hasRegistrationCodeHash() {
    return this.registrationCodeHash ? true : false;
  }

  setRegistrationCodeHash( v: string ): Promise<void> {
    this.registrationCodeHash = v;
    if (this.isLoggedIn()) {
      this.registrationCodeHashOk = true;
      return this.createOrUpdateUserInternal({id: this.userInfo.user.id, registrationCodeHash: this.registrationCodeHash});
    } else {
      console.log('no user logged in, have to update user internal with new registrationCodeHash later')
      return Promise.resolve();
    }
  }

  createOrUpdateUserInternal( userInternal: UserInternal ): Promise<void> {
    console.log('updating user internal: '+userInternal.id);
    return this.db.collection<UserInternal>(this.nameInternal).doc(userInternal.id)
    .set(userInternal, {merge: true});
  }

  confirmEv(obj: UserInfo) {
    const evCategories = obj.getEvCategories();
    const rolesCollection = this.collection.doc(obj.user.id).collection<Role>('roles')
    const existingEvCategories = obj.getConfirmedEvCategories()
    // delete no longer existing categories
    _.difference(existingEvCategories, evCategories)
    .forEach( c => rolesCollection.doc('ev'+c).delete())
    // create new categories
    _.difference(evCategories, existingEvCategories)
    .forEach( c => rolesCollection.doc('ev'+c).set({id: 'ev'+c, userId: obj.user.id }))
    // create or delete main Ev role
    if (evCategories.length>0) rolesCollection.doc('ev').set({id: 'ev', userId: obj.user.id })
    else rolesCollection.doc('ev').delete()
    this.notificationSvc.showInfo(`EV Rollen für Benutzer ${obj.user.email} bestätigt`);
  }

  addRole(obj: User, role: string) {
    const rolesCollection = this.collection.doc(obj.id).collection<Role>('roles');
    rolesCollection.doc(role).set({id: role, userId: obj.id })
    .catch( err => this.notificationSvc.showError(`Error: ${err}`))
    .then( _ => this.notificationSvc.showInfo(`Rolle ${role} für Benutzer ${obj.email} hinzugefügt`));
  }

  removeRole(obj: User, role: string) {
    const rolesCollection = this.collection.doc(obj.id).collection<Role>('roles');
    rolesCollection.doc(role).delete()
    .catch( err => this.notificationSvc.showError(`Error: ${err}`))
    .then( _ => this.notificationSvc.showInfo(`Rolle ${role} für Benutzer ${obj.email} entfernt`));
  }

  updateCode(obj: UserInfo) {
    if (this.isLoggedIn() && this.userInfo.isAdmin()) {
      this.createOrUpdateUserInternal({id: obj.user.id, registrationCodeHash: this.config.config.registrationCodeHash})
      .then( x => this.notificationSvc.showInfo(`Registrationscode für ${obj.user.email} aktualisiert`))
      .catch( err => this.notificationSvc.showError(`Error: ${err}`));
    } else {
      this.notificationSvc.showError(`Keine Berechtigungen`);
    }
  }

  getShortName( id: string ): Observable<string> {
    return this.getCached(id).pipe( map( user => {
      if (user.email) { // if user is deleted, we dont find it anymore...
        return user.firstName.substr(0,1) + '.' + user.lastName;
      } else return "Undefined";
    }));  
  }
}
