import { Component, OnInit } from '@angular/core';
import { ConfigService } from '../shared/config.service';
import { MediaService } from 'src/app/media/shared/media.service';
import { DialogService } from 'src/app/shared/dialog.service';
import * as _ from 'underscore';
import { UserService } from 'src/app/user/shared/user.service';
import { map, shareReplay, first, takeUntil, tap, filter, debounceTime, mergeMap } from 'rxjs/operators';
import { CategoryRegistration, User } from 'src/app/user/shared/user';
import { MailjobService } from '../shared/mailjob.service';
import { Recipient } from '../shared/mailjob';

@Component({
  selector: 'app-admin-form',
  templateUrl: './admin-form.component.html',
  styleUrls: ['./admin-form.component.css']
})
export class AdminFormComponent implements OnInit {

  constructor(public configSvc: ConfigService, public mediaSvc: MediaService, public dialogSvc: DialogService, 
              private userSvc: UserService, private mailSvc: MailjobService) { }

  ngOnInit() {
  }

  enableNotifications() {
    this.dialogSvc.openTextEntryDialog("Enable Notifications", "Bitte Emailadressen zum Aktivieren der Benachrichtigungen mit Semikolon getrennt eintragen<br/>(format: 'emailadresse;'):", "Erstellen")
      .then( (emails: String) => {
        if (emails) { 
          console.log("starting update");
          this.userSvc.getUserInfoList().pipe(first()).toPromise().then( userList => {
            console.log("got users, updating emails: "+emails);
            var u1 = emails.split(";")
            .map(email => email.trim())
            .filter(email => email.length > 0);
            u1.forEach(
              email => {
                // check if user is existing
                var existingUser = userList.find(x => x.user.email == email);
                if (existingUser) {
                  // update user profile
                  if (!existingUser.user.mailNotify) {
                    this.userSvc.update({id: existingUser.user.id, mailNotify: true})
                    .then(() => console.log(`updated user ${email}`));
                  } else {
                    console.log(`user is up-to-date: ${email}`);
                  }
                } else {
                  console.log(`user not existing: ${email}`);
                }
              }
            )
          });
        }
      });
  }

  importUsers() {
    this.dialogSvc.openTextEntryDialog("Import Users", "Bitte hier User/Emailadressen zum Erstellen mit Semikolon getrennt eintragen<br/>(format: 'name vorname klasse1[(name)] [klasse2[(name)]...] &lt;emailadresse&gt;;'):", "Erstellen")
      .then( (users: String) => {
        if (users) { 
          console.log("starting import");
          this.userSvc.getUserInfoList().pipe(first()).toPromise().then( userList => {
            console.log("got users, import users: "+users);
            var newUsers: Promise<Partial<User>|void>[] = [];
            var updatedUsers: Promise<Partial<User>|void>[] = [];
            var allCategories = this.configSvc.categoryPaths.map(c => c.category.id);
            console.log("all categories", allCategories);
            var u1 = users.split(";")
            .map(user => user.trim())
            .filter(user => user.length > 0);
            u1.forEach(
              user => {
                // parse email
                var rEmail = /<(.*)>/g;
                var sEmails = rEmail.exec(user);
                if (!sEmails) console.warn("Emailadresse nicht gefunden in "+user);
                else {
                  // parse details
                  var email = sEmails[1];
                  var info = user.split("<")[0].trim();
                  var infoWords = info.split(" ");
                  var evCategoriesRaw = _.intersection(infoWords, allCategories.map(c => "ev"+c));
                  var evCategoriesUnfiltered = evCategoriesRaw.map(c => c.substr(2));
                  var categoriesWithName = infoWords.filter(w => w.indexOf("(")>0);
                  var categoriesWithoutName = _.intersection(infoWords, allCategories);
                  var categoryRegs = _.union(categoriesWithName,categoriesWithoutName)
                  .map( c => {
                    var rName = /\((.*)\)/g;
                    var sNames = rName.exec(c);
                    var category = c.split("(")[0].trim();
                    var representative = _.contains(evCategoriesUnfiltered, category);
                    if (!sNames) {
                      console.warn("Name zu Kategorie nicht gefunden in "+c);
                      return ({category: category, description: "-", representative: representative });
                    } else {
                      return ({category: category, description: sNames[1], representative: representative });
                    }
                  })
                  var evCategoryRegs = evCategoriesUnfiltered
                    .filter(evCat => categoryRegs.findIndex(cat => cat.category == evCat) == -1)
                    .map(c => ({category: c, description: "-", representative: true }));
                  //var categories = _.intersection(infoWords, allCategories);
                  var allCategoryRegs = _.union(categoryRegs, evCategoryRegs);
                  if (allCategoryRegs.length == 0) console.warn("Kategorien nicht gefunden in "+user);
                  var names = _.difference(infoWords, categoriesWithName, categoriesWithoutName, evCategoriesRaw);
                  var firstName = _.last(names);
                  var lastName = _.initial(names).reduce((a,b) => a+" "+b);
                  console.log(`handling user ${email}`);
                  // check if user is existing
                  var existingUser = userList.find(x => x.user.email == email);
                  if (!existingUser) {
                    // signup
                    var userData: Partial<User> = {
                      email: email,
                      firstName: firstName,
                      lastName: lastName,
                      parentalRole: 'other',
                      categories: allCategoryRegs,
                      mailNotify: true
                    };
                    var pw = this.userSvc.generatePassword(8);
                    var newUserRequest = this.userSvc.emailSignUp(email, pw, userData, false)
                    .then(user => {
                      console.log(`created user ${firstName} ${lastName} ${email}: categories=${allCategoryRegs.map(c => JSON.stringify(c))}`);
                      return user
                    })
                    .catch(err => console.error(`error creating user ${firstName} ${lastName} ${email}: ${err}`));
                    newUsers.push(newUserRequest);
                  } else {
                    // update hashcode
                    var updateInternalUserRequest: Promise<void | Partial<User>> = Promise.resolve();
                    if (existingUser.internal.registrationCodeHash != this.configSvc.config.registrationCodeHash) {
                      updateInternalUserRequest = this.userSvc.createOrUpdateUserInternal({id: existingUser.user.id, registrationCodeHash: this.configSvc.config.registrationCodeHash})
                      .then(() => console.log(`updated internal user ${firstName} ${lastName} ${email}`))
                      .then(() => ({id: existingUser.user.id, email: email}))
                    }
                    // update categories and name
                    var updateUserRequest: Promise<void | Partial<User>> = Promise.resolve();
                    if (!_.isEqual(existingUser.getCategoryRegistrations(), allCategoryRegs) || existingUser.user.lastName != lastName || existingUser.user.firstName != firstName) {
                      updateUserRequest = this.userSvc.update({id: existingUser.user.id, categories: allCategoryRegs, lastName: lastName, firstName: firstName})
                      .then(() => console.log(`updated user ${firstName} ${lastName} ${email}: categories=${allCategoryRegs.map(c => JSON.stringify(c))}`))
                      .then(() => ({id: existingUser.user.id, email: email}))
                    }                   
                    // combine the two promises
                    var combinedUpdateUserRequest = updateInternalUserRequest.then(user1 => updateUserRequest.then(user2 => user1 || user2))
                    .catch(err => console.error(`error updating user ${firstName} ${lastName} email=${email}: ${err}`));
                    updatedUsers.push(combinedUpdateUserRequest);
                  }
                }
              }
            );
            const loginUrl = this.configSvc.config.baseUrl + '/home?login';
            // send email to new users
            Promise.all(newUsers)
            .then(usersToNotify => {
              const recipients: Recipient[] = usersToNotify
                .filter(user => user != null)
                .map(user => user as Partial<User>)
                .map(user => ({ userKey: user.id, email: user.email }));
              if (recipients.length > 0) {
                console.log( `sending new user email to ${recipients.length} recipients`);
                const title = this.configSvc.config.mailPrefix + ' ' + "Benutzeraccount erstellt auf www.ef3047.ch";
                let content = '<p>Liebe Eltern</p>\n';
                content += `<p>Seit dem 1. Januar 2023 gibt das Schulsekretariat dem Elternforum jährlich die E-Mail-Adressen der Eltern von Schülerinnen und Schülern der Schulen Bremgarten weiter.</p>\n`;
                content += `<p>Das Elternforum hat für Sie daher auf der Webseite <a href='https://www.ef3047.ch'>www.ef3047.ch</a> automatisch einen Benutzeraccount erstellt, damit Sie künftig alle Informationen vom Elternforum, die ihr Kind betreffen, per E-Mail zugestellt erhalten.</p>\n`;
                content += `<p>Ein Einloggen auf der Webseite ist nicht nötig. Wenn sie Ihr Profil ansehen oder anpassen wollen, loggen Sie sich bitte auf der Elternforum-Webseite ein. Dazu müssen sie einmalig das Passwort zurücksetzen. Geben sie dazu auf <a href='`+ loginUrl +`'>`+loginUrl.replace("https://","")+`</a> ihre Emailadresse und ein beliebiges Passwort ein.  Es wird dann ein Schalter zum Zurücksetzen des Passworts angezeigt. Wenn Sie diesen Schalter anwählen, wird ihnen eine E-Mail gesendet, welches einen Link zum Zurücksetzen des Passworts enthält. Danach können Sie sich mit dem neuen Passwort auf der Elternforum-Webseite einloggen.</p>\n`;
                content += `<p>Möchten Sie keine Informationen von Elternforum erhalten, können Sie sich jeweils mit dem Link ganz unten im Mail abmelden.</p>\n`;
                content += '<p>Besten Dank und freundliche Grüsse</p><p>Sekretariat Elternforum</p>\n';
                this.mailSvc.create({ title: title, content: content, contentKey: 'newUserEmail', recipients: recipients })
                .then(() => "sent new user email");
              }
            });
            // send email to updated users
            Promise.all(updatedUsers)
            .then(usersToNotify => {
              const recipients: Recipient[] = usersToNotify
                .filter(user => user != null)
                .map(user => user as Partial<User>)
                .map(user => ({ userKey: user.id, email: user.email }));
              if (recipients.length > 0) {
                console.log( `sending updated user email to ${recipients.length} recipients`);
                const title = this.configSvc.config.mailPrefix + ' ' + "Benutzeraccount aktualisiert auf www.ef3047.ch";
                let content = '<p>Liebe Eltern</p>\n';
                content += `<p>Seit dem 1. Januar 2023 gibt das Schulsekretariat dem Elternforum jährlich die E-Mail-Adressen der Eltern von Schülerinnen und Schülern der Schulen Bremgarten weiter.</p>\n`;
                content += `<p>Sie sind bereits mit Ihrer E-Mailadresse auf der Webseite <a href='https://www.ef3047.ch'>www.ef3047.ch</a> als Benutzer registriert und ihr Benutzeraccount wurde nun automatisch aktualisiert.</p>\n`;
                content += `<p>Ein Einloggen auf der Webseite ist nicht nötig. Wenn sie Ihr Profil ansehen oder anpassen wollen, loggen Sie sich bitte auf der Elternforum-Webseite ein. Wenn sie ihr Passwort nicht mehr kennen, können sie es wie folgt zurücksetzen: Geben sie auf <a href='`+ loginUrl +`'>`+loginUrl.replace("https://","")+`</a> ihre Emailadresse und ein beliebiges Passwort ein.  Es wird dann ein Schalter zum Zurücksetzen des Passworts angezeigt. Wenn Sie diesen Schalter anwählen, wird ihnen eine E-Mail gesendet, welches einen Link zum Zurücksetzen des Passworts enthält. Danach können Sie sich mit dem neuen Passwort auf der Elternforum-Webseite einloggen.</p>\n`;
                content += `<p>Möchten Sie keine Informationen von Elternforum erhalten, können Sie sich jeweils mit dem Link ganz unten im Mail abmelden.</p>\n`;
                content += '<p>Besten Dank und freundliche Grüsse</p><p>Sekretariat Elternforum</p>\n';
                this.mailSvc.create({ title: title, content: content, contentKey: 'updatedUserEmail', recipients: recipients })
                .then(() => "sent updated user email");
              }
            })            
          });
        }
      });
  }


  increaseUsersCategories() {
    const categoryIncreaseMarker = new Date().getFullYear().toString();
    console.log("start increasing user categories for "+categoryIncreaseMarker);
    this.userSvc.getUserInfoList().pipe(first()).toPromise().then( userList => {
      console.log("got "+userList.length+" users");
      var updatedUsers: Promise<Partial<User>|void>[] = [];
      // prepare category mapping
      var categoryClassPattern = /^[1-9]/
      var categoriesToUpdateMap = new Map<string, string>();
      this.configSvc.categoryPaths.map(c => c.category)
        .filter(c => c.isLeaf())
        .forEach(c => {
          if (c.id.startsWith("9")) { // Neunte Klasse kann gelöscht werden
            categoriesToUpdateMap.set(c.id, "delete");
          } else if (categoryClassPattern.test(c.id)) { // Andere Klassen werden um 1 erhöht
            categoriesToUpdateMap.set(c.id, Number(c.id.substr(0,1))+1 +"a"); // es wird immer der "a" Klasse zugeordnet, da nicht alle Jahrgänge gleich viele parallele Klassen haben.
          }
          // Notiz: Ob die Kindergärteler in die erste Klasse kommen ist nicht klar, gibt es da ja 2 Jahrgänge in der gleichen Kategorie.
          // kg* Kategorien werden also so belassen wie sie sind.
        });
      console.log("category update plan", categoriesToUpdateMap);
      userList
      .filter(user => user.internal.lastCategoryIncrease != categoryIncreaseMarker) // nur noch nicht aktualisierte user
      .filter(user => this.configSvc.isRegistrationCodeHashOk(user.internal.registrationCodeHash)) // nur aktuelle user
      .forEach(
        user => {
          console.log(user);
          // update categories
          var updateUserRequest: Promise<void | Partial<User>> = Promise.resolve();
          const categories = user.getCategoryRegistrations();
          const updatedCategories = categories.map(c => {
            const updatedCategory = categoriesToUpdateMap.get(c.category)
            if (updatedCategory) {
              return {category: updatedCategory, description: c.description, representative: c.representative || false}
            } else return c
          })
            .filter(c => c.category != "delete"); // filter Einträge für gelöschte 9.Klassen
          if (!_.isEqual(categories, updatedCategories)) {
            //console.log(`updating user ${user.user.firstName} ${user.user.lastName} ${user.user.email}`, categories, updatedCategories);
            updateUserRequest = this.userSvc.update({id: user.user.id, categories: updatedCategories})
            .then(() => console.log(`updated user ${user.user.firstName} ${user.user.lastName} ${user.user.email}`, categories, updatedCategories))
            .then(() => ({id: user.user.id, email: user.user.email}))
          }
          // update lastCategoryIncrease
          // this is set for all users, not only for the modified ones...
          var updateInternalUserRequest = this.userSvc.createOrUpdateUserInternal({id: user.user.id, lastCategoryIncrease: categoryIncreaseMarker})
          .then(() => console.log(`updated internal user ${user.user.firstName} ${user.user.lastName} ${user.user.email}`))
          .then(() => ({id: user.user.id, email: user.user.email}))
          // combine the two promises
          var combinedUpdateUserRequest = updateInternalUserRequest.then(user1 => updateUserRequest.then(user2 => user1 || user2))
          .catch(err => console.error(`error updating user ${user.user.firstName} ${user.user.lastName} email=${user.user.email}: ${err}`));
          updatedUsers.push(combinedUpdateUserRequest);
        }
      );
      Promise.all(updatedUsers)
      .then(users => console.log("Updated "+users.length+" users"));
    });
  }
}
