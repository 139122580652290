import { Component, OnInit, Input } from '@angular/core';
import { ArticleService } from '../shared/article.service';
import { Article } from '../shared/article';
import { Observable, Subject, of } from 'rxjs';
import { map, filter, tap, find, first } from 'rxjs/operators';

import * as _ from 'underscore';
import { ConfigService } from 'src/app/admin/shared/config.service';
import { ActivatedRoute, Router } from '@angular/router';
import { UserService } from 'src/app/user/shared/user.service';
import { Category, CategoryPath } from 'src/app/admin/shared/config';

@Component({
  selector: 'app-articles-blog',
  templateUrl: './articles-blog.component.html',
  styleUrls: ['./articles-blog.component.css'],
})
export class ArticlesBlogComponent implements OnInit {
  @Input() selector;
  @Input() title;

  public objsSrc: Observable<Article[]>;
  public objsObs: Observable<Article[]>;
  public tags = [];
  public limit = 0;
  public increment = 10;
  public hasMore = false;
  public selectorCons: String;
  public titleCons: String;

  constructor(private svc: ArticleService, private configSvc: ConfigService, private route: ActivatedRoute
            , private router: Router, private user: UserService) { }

  ngOnInit() {
    this.route.data.pipe( first() ).subscribe( data  => {
      this.selectorCons = this.selector || data.selector || 'public';
      this.titleCons = this.title || (this.selectorCons=='my' ? 'Meine Veranstaltungen und Informationen' : 'Allgemeine Veranstaltungen und Informationen' );
      console.log(`selecting articles for "${this.selectorCons}"`);
      const currentDate = new Date().setUTCHours(0,0,0,0)
      switch (this.selectorCons) {
        case 'public':
          this.objsSrc = this.svc.getPublicArticles().pipe( 
            map( articles => articles.filter( a => a.type !== 'event' || this.toMillis(a.date) >= currentDate)), // filter out past events
            map( articles => articles.filter( a => a.type !== 'info' || !a.exiprationDate || this.toMillis(a.exiprationDate) >= currentDate)), // filter out past infos
            map( articles => _.sortBy(articles, a => (isNaN(a.date) ? a.tstmp : a.date)).reverse()) // sort date descending
          );
          break;
        case 'my':
          this.objsSrc = this.svc.getMyArticles().pipe( 
            map( articles => articles.filter( a => a.type !== 'event' || this.toMillis(a.date) >= currentDate)), // filter out past events
            map( articles => articles.filter( a => a.type !== 'info' || !a.exiprationDate || this.toMillis(a.exiprationDate) >= currentDate)), // filter out past infos
            map( articles => _.sortBy(articles, a => (isNaN(a.date) ? a.tstmp : a.date)).reverse()) // sort date descending
          );
          break;
        default:
          // select only articles which have category = selector
          this.objsSrc = this.svc.getPublicArticles().pipe(
            map( articles => articles.filter( article => _.contains(article.categories, this.selector))),
            map( articles => articles.filter( a => a.type !== 'event' || this.toMillis(a.date) >= currentDate)), // filter out past events
            map( articles => articles.filter( a => a.type !== 'info' || !a.exiprationDate || this.toMillis(a.exiprationDate) >= currentDate)), // filter out past infos
            map( articles => _.sortBy(articles, a => (isNaN(a.date) ? a.tstmp : a.date)).reverse()) // sort date descending
          );
      }
      // extract tags from all arcticles (categories and tags)
      this.objsSrc.subscribe( articles => {
        const ids = _.sortBy(this.getTagIds(articles).concat(this.getCategoryIds(articles)), id => id.toLowerCase());
        this.tags = ids.map( id => {
          const tag = this.configSvc.getCategory(id) || new Category(id);
          tag['selected'] = false;
          return tag;
        })
        .filter( c => !c.hidden || false );
      });
      this.limit = 0;
      this.loadNextObjs();
    });
  }

  toMillis( date: any ): number {
    if (date && date.seconds) {
      return date.seconds * 1000;
    } else {
      return date;
    }
  }

  getCategoryIds(articles: Article[]): string[] {
    switch (this.selectorCons) {
      case 'public':
        // limit to first non empty category level
        return this.configSvc.getFirstNonEmptyCategoryLevel().map( c => c.id )
        .filter( id => id!='ev' && id!='vorstand'); // dont show these categories in public view
      case 'my':
        // limit to relevant categories of user
        return this.user.myCategories;
      default:
        // show categories of articles in list
        return _.chain(articles)
        .map( article => this.svc.getExpandedCategories(article))
        .flatten().unique().value();
    }
  }

  getTagIds(articles: Article[]): string[] {
    return _.chain(articles)
    .map( article => article.tags )
    .flatten().unique().value();
  }

  loadNextObjs() {
    this.limit = this.limit + this.increment;
    this.loadObjs();
  }

  loadObjs() {
    this.objsObs = this.objsSrc.pipe(
      // filter selected tags
      map( objs => {
        console.log(`intially ${objs.length} articles`, objs);
        const selectedTags = this.tags.filter( tag => tag.selected ).map( tag => tag.id );
        return objs.filter( obj => {
          const articleTags = this.svc.getExpandedCategories( obj ).concat(obj.tags);
          return _.intersection( articleTags, selectedTags ).length === selectedTags.length;
        });
      }),
      // take until limit
      map( objs => {
        console.log(`got ${objs.length} articles`);
        this.hasMore = (objs.length > this.limit);
        return _.take(objs, this.limit);
      })
    );
  }

  selectTag(tag) {
    tag.selected = !tag.selected;
    this.loadObjs();
  }

  gotoArticle(obj: Article) {
    this.router.navigate(['article', obj.id]);
  }
}
